<template>
  <div id="map">
    <l-map
      v-if="loaded"
      ref="map"
      :zoom="zoom"
      :center="center"
      :options="{ minZoom: 3, maxZoom: 18 }"
      style="height: 700px; width: 100%; max-height: 100%; z-index: 4"
      @ready="updateLayout"
    >
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-control>
        <v-card v-if="displayLegend" max-width="300">
          <v-card-title>
            {{ $t("map.livemap.legend.title") }}
            <v-spacer />
            <v-btn icon @click="() => (displayLegend = false)">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p>{{ $t("map.livemap.legend.description.1") }}</p>
            <p>{{ $t("map.livemap.legend.description.2") }}</p>
            <p>{{ $t("map.livemap.legend.description.3") }}</p>

            <h3>{{ $t("map.livemap.legend.settingsTitle") }}</h3>
            <v-select
              v-model="showTypes"
              :items="Object.values(nodeTypes)"
              :label="$t('map.livemap.legend.typeSelectionPlaceholder')"
              multiple
            >
              <template v-slot:item="data">
                <v-list-item-content class="pa-2">
                  <v-list-item-title
                    v-html="$t('tag.nodeTypes.' + data.item)"
                  />
                </v-list-item-content>
              </template>

              <template v-slot:selection="data">
                <v-chip small>{{ $t("tag.nodeTypes." + data.item) }}</v-chip>
              </template>
            </v-select>

            <p>{{ $t("map.livemap.legend.intervalSliderLabel") }}</p>
            <v-slider
              step="1"
              thumb-label
              ticks
              prepend-icon="mdi-alarm"
              validate-on-blur
              v-model="lastContactIndex"
              @end="updateIntervall"
              :max="slidervalues.length - 1"
              :min="0"
            >
              <template v-slot:thumb-label="{ value }">
                {{ slidervalues[value] ? slidervalues[value] + "h" : "N/A" }}
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-btn icon @click="() => (displayLegend = true)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card>
      </l-control>

      <v-marker-cluster :options="clusterOptions" ref="clusterRef">
        <l-marker
          v-for="(item, i) in tagsWithCoordinates"
          :key="item.deveui + i"
          :lat-lng="item.latLng"
          :icon="getIcon(item)"
          :options="{
            value: getIconColorValue(item),
          }"
        >
          <l-popup>
            <h3>{{ $t("tag.fields.deveui") }}</h3>
            <p class="headline-3">{{ item.deveui }}</p>

            <h3>{{ $t("tag.fields.type") }}</h3>
            <p class="headline-3">{{ $t("tag.nodeTypes." + item.nodeType) }}</p>

            <div v-if="item.attributeDict.RSSI">
              <h3>{{ $t("signalTest.fields.data.rssi") }}</h3>
              <p class="headline-3">{{ item.attributeDict.RSSI }}</p>
            </div>

            <div v-if="item.attributeDict.SNR">
              <h3 class="headline-2">{{ $t("signalTest.fields.data.snr") }}</h3>
              <p class="headline-3">{{ item.attributeDict.SNR }}</p>
            </div>

            <div v-if="item.attributeDict.BATTERY">
              <h3 class="headline-2">
                {{ $t("signalTest.fields.data.battery") }}
              </h3>
              <p class="headline-3">{{ item.attributeDict.BATTERY }}</p>
            </div>

            <div v-if="item.attributeDict.GATEWAY">
              <h3 class="headline-2">
                {{ $t("signalTest.fields.data.lastGateway") }}
              </h3>
              <p class="headline-3">{{ item.attributeDict.GATEWAY }}</p>
            </div>

            <h3 class="headline-2">
              {{ $t("signalTest.fields.data.createdAt") }}
            </h3>
            <p class="headline-3">{{ humanDate(item.lastContact) }}</p>
          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as L from "leaflet/dist/leaflet-src.esm";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LPopup, LControl } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";
import CsharpEnum from "../../_helpers/CsharpEnum";
import DateHelper from "../../_helpers/DateHelper";
import { FEATURES } from "../../_helpers/RestrictHelper";

const colorvalue = {
  NO_SIGNAL: 0,
  GOOD: 1,
  AVERAGE: 2,
  BAD: 3,
};

export default {
  name: "LiveMap",

  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster,
    LControl,
  },

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      loaded: false,
      defaultCenter: null,
      defaultZoom: 12,
      center: null,
      zoom: null,
      displayLegend: false,
      lastContactInterval: null,
      lastContactIndex: 0,
      slidervalues: [],
      showTypes: [],
      updateTimer: null,
      displayAttributes: [],

      iconSize: 30,
      fontSize: 10,
      iconColorGood: "#64de64", // Green
      iconColorAvg: "#fffc38", // Yellow
      iconColorBad: "#fa4d4d", // Red
      iconColorNoSignal: "#8f8f8f", // Gray
      clusterOptions: {
        iconCreateFunction: (cluster) => {
          return this.clusterIconCreateFunction(cluster);
        },
      },
    };
  },

  computed: {
    ...mapGetters("configuration", [
      "getCompanyCoordinates",
      "getCompanyMapSettings",
    ]),

    tagsWithCoordinates() {
      this.$nextTick(() => {
        this.$refs.clusterRef?.mapObject?.refreshClusters();
      });
      return this.tags
        .filter((x) => {
          return (
            x.latitude != null &&
            x.longitude != null &&
            this.showTypes.includes(x.nodeType)
          );
        })
        .map((x) => ({
          ...x,
          latLng: L.latLng(x.latitude, x.longitude),
        }));
    },

    nodeTypes() {
      let types = CsharpEnum.NodeTypes;
      if (!this.canAccessFeature(FEATURES.OPC)) delete types.OPC;
      return types;
    },

    sliderValues() {
      return this.slidervalues;
    },
  },

  methods: {
    clusterIconCreateFunction(cluster) {
      // Calculates the worst color among the markers in the cluster
      let worstColor = cluster
        .getAllChildMarkers()
        .reduce((acc, loc) =>
          Number(acc.options.value) > Number(loc.options.value) ? acc : loc
        ).options.value;

      return new L.DivIcon({
        html: `<div style="background-color:${this.getIconColor(
          Number(worstColor)
        )}; font-weight: 900;opacity: 0.8;"><span>${cluster.getChildCount()}</span></div>`,
        className: "marker-cluster",
        iconSize: new L.Point(this.iconSize, this.iconSize),
      });
    },

    updateIntervall() {
      this.lastContactInterval = this.slidervalues[this.lastContactIndex];
      this.refresh();
    },

    displayTextCalc(item) {
      let normalizedName = this.displayAttributes.find(
        (x) => x.nodeType == item.nodeType
      )?.attribute?.normalizedName;

      return normalizedName ? item.attributeDict[normalizedName] ?? "N/A" : "";
    },

    getIcon(item) {
      switch (item.nodeType) {
        case this.nodeTypes.LORAWAN:
          return this.createIcon(
            this.getLorawanIconColorCalc(
              item.attributeDict.RSSI,
              item.attributeDict.SNR
            ),
            this.displayTextCalc(item)
          );
        default:
          return this.createIcon(
            this.getLastContactIconColorCalc(item.lastContact),
            this.displayTextCalc(item)
          );
      }
    },

    createIcon(backgroundColor, text) {
      //the width, height, border-radius, font in div and line-height in span overrides marker-cluster class styling
      return new L.DivIcon({
        html: `<div style="background-color:${backgroundColor}; border:0.01px solid black; width:${
          this.iconSize
        }px; height:${this.iconSize}px; border-radius:${
          this.iconSize / 2
        }px; font: ${
          this.fontSize
        }px 'Helvetica Neue', Arial, Helvetica, sans-serif;"><span style="line-height: ${
          this.iconSize
        }px; white-space: nowrap;" class="d-flex justify-center align-center icon-text-outliner">${
          text ?? "N/A"
        }</span></div>`,
        className: "marker-cluster",
        iconSize: new L.Point(this.iconSize, this.iconSize),
        popupAnchor: new L.Point(5, 0),
      });
    },

    getLorawanIconColorCalc(rssi, snr) {
      return this.getIconColor(this.getLorawanIconColorValue(rssi, snr));
    },

    getLastContactIconColorCalc(lastContact) {
      return this.getIconColor(this.getLastContactColorValue(lastContact));
    },

    getIconColor(value) {
      switch (value) {
        case colorvalue.NO_SIGNAL:
          return this.iconColorNoSignal;
        case colorvalue.GOOD:
          return this.iconColorGood;
        case colorvalue.AVERAGE:
          return this.iconColorAvg;
        case colorvalue.BAD:
          return this.iconColorBad;
        default:
          return this.iconColorNoSignal;
      }
    },

    getIconColorValue(item) {
      switch (item.nodeType) {
        case this.nodeTypes.LORAWAN:
          return this.getLorawanIconColorValue(
            item.attributeDict.RSSI,
            item.attributeDict.SNR
          );
        default:
          return this.getLastContactColorValue(item.lastContact);
      }
    },

    getLorawanIconColorValue(rssi, snr) {
      return Math.max(this.getRssiColorValue(rssi), this.getSnrColorValue(snr));
    },

    getRssiColorValue(rssi) {
      if (rssi === null || rssi === undefined) {
        return colorvalue.NO_SIGNAL;
      } else if (rssi > -115) {
        return colorvalue.GOOD;
      } else if (rssi <= -120) {
        return colorvalue.BAD;
      } else {
        return colorvalue.AVERAGE;
      }
    },

    getSnrColorValue(snr) {
      if (snr === null || snr === undefined) {
        return colorvalue.NO_SIGNAL;
      } else if (snr > 0) {
        return colorvalue.GOOD;
      } else if (snr <= -10) {
        return colorvalue.BAD;
      } else {
        return colorvalue.AVERAGE;
      }
    },

    getLastContactColorValue(lastContact) {
      if (lastContact === null || lastContact === undefined) {
        return colorvalue.NO_SIGNAL;
      }
      return DateHelper.methods.timeWithinHours(
        lastContact,
        this.lastContactInterval
      )
        ? colorvalue.GOOD
        : colorvalue.BAD;
    },

    async updateLayout() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.invalidateSize();
        this.fitToTags();
      });
    },

    fitToTags() {
      let boundsDict = L.latLngBounds(
        this.tagsWithCoordinates.map((x) => [x.latLng.lat, x.latLng.lng])
      );
      let boundsArray = Object.values(boundsDict).map((x) => Object.values(x));

      this.$nextTick(() => {
        if (boundsArray.length > 0) {
          this.$refs.map.mapObject.fitBounds(boundsArray, {
            padding: [10, 10],
          });
          return;
        }
        this.$refs.map.mapObject.setView(this.defaultCenter, this.defaultZoom);
      });
    },

    refresh() {
      //pushes and pops to showTypes to force an update to tagsWithCoordinates and therefor update markers
      this.showTypes.push({});
      this.showTypes.pop();
      this.$nextTick(() => {
        this.$refs.clusterRef?.mapObject?.refreshClusters();
      });
    },
  },

  mounted() {
    this.center = this.defaultCenter;
    this.zoom = this.defaultZoom;
    this.loaded = true;
    this.updateTimer = setInterval(this.refresh, 5 * 60000);
  },

  beforeDestroy() {
    clearInterval(this.updateTimer);
  },

  created() {
    this.defaultCenter = this.getCompanyCoordinates;
    this.defaultCenter =
      this.defaultCenter.latitude != null &&
      this.defaultCenter.longitude != null
        ? (this.defaultCenter = L.latLng(
            this.defaultCenter.latitude,
            this.defaultCenter.longitude
          ))
        : L.latLng(58.38999888912673, 13.857677403817654);
    let temp = this.getCompanyMapSettings;
    this.displayAttributes = temp.displayAttributes;
    this.showTypes = temp.showTypes;
    this.slidervalues = temp.slidervalues.length > 0 ? temp.slidervalues : [1];
    this.lastContactInterval = this.slidervalues[0];
  },

  watch: {
    tags: {
      //Needs to be shallow otherwise map would fit to the tags on attribute updates
      handler() {
        this.fitToTags();
      },
    },
  },
};
</script>

<style>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.leaflet-div-icon {
  background: transparent !important;
  border: transparent !important;
}

.v-list-item {
  min-height: 20px;
}

.icon-text-outliner {
  color: white;
  text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
    1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
}
</style>
